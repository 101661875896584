import { useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import useAccountQuery from 'queries/useAccountQuery';
import useForm from '@activebrands/core-web/hooks/useForm';
import Events from '@activebrands/core-web/libs/Events';
import { AccountEvents } from '@activebrands/core-web/libs/Events/types';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import { signUpNewsletter, updateUser } from '@activebrands/core-web/state/auth/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import { logger } from '@activebrands/core-web/utils/logger';
import InputErrors from 'components/Form/InputErrors';
import ThemeButton from 'components/buttons/ThemeButton';
import LoadingIcon from 'components/icons/LoadingIcon';
import StateIcon from 'components/icons/StateIcon';
import Checkbox from 'components/inputs/CheckboxInput';
import Input from 'components/inputs/Input';
import Paragraph from 'components/text/Paragraph';
import { navigate } from 'gatsby';

const fields = [
    ['firstname', fm('Firstname'), 'firstname'],
    ['lastname', fm('Lastname'), 'lastname'],
];

const SignInConsent = () => {
    const dispatch = useTypedDispatch();
    const [css] = useStyletron();

    const user = useSelector(state => state.auth.user);
    const {
        accountPage,
        signInActMembershipHeading,
        signInActMembershipLabel,
        signInActMembershipConsent,
        signInActTosConsent,
    } = useAccountQuery();

    const [{ isLoading, errors }, setState] = useState({
        isLoading: false,
        isSubmitted: false,
        email: undefined,
        errors: [],
    });

    const form = useForm(async field => {
        const { firstname, lastname, confirmMembership } = field;

        setState(prevState => ({ ...prevState, isLoading: true }));

        const token = user?.token;

        try {
            //acknowledgement will make use a member in Netcomapny's API, and next time we get customer data we will get a memberships array with confirmed membership.
            const response = await dispatch(
                updateUser({
                    firstname,
                    lastname,
                    acknowledgement: 'Yes',
                    token,
                })
            );

            if (response?.message === 'Put request success.') {
                setState(prev => ({ ...prev, isSubmitted: true, errors: [], isLoading: false }));

                Events.trigger(AccountEvents.SIGN_IN, {
                    userId: user?.contactId,
                    customerNumber: user?.customerNumber,
                });

                //Redirect to my account
                accountPage && navigate(accountPage);
            } else {
                setState(prev => ({ ...prev, errors: [response.error], isLoading: false }));
            }
        } catch (error) {
            //sign out customer if consent request fails
            dispatch({ type: 'AUTH_SIGN_OUT_SUCCESS' });
        }
        if (confirmMembership) {
            try {
                if (!user?.email) {
                    throw new Error('User email is missing. Cannot sign up for newsletter.');
                }

                const newsletterResponse = await dispatch(
                    signUpNewsletter({
                        userEmail: user?.email,
                    })
                );

                if (newsletterResponse?.status === 200) {
                    return newsletterResponse;
                } else {
                    throw new Error(`Unexpected response status: ${newsletterResponse?.status}. Expected 200.`);
                }
            } catch (error) {
                logger.error('Error during newsletter signup', {
                    errorMessage: error.message,
                    userEmail: user?.email,
                    stack: error.stack,
                    response: error.response || null,
                });
            }
        }
    });

    const handleChange = event => {
        form.errors[event.target.name] ? form.validateInput(event) : undefined;
    };

    return (
        <>
            <div
                className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                })}
            >
                <Paragraph $style={{ marginBottom: '16px' }} fontKeys="Miscellaneous/16_120_-1_500">
                    {signInActMembershipHeading}
                </Paragraph>

                <RichText data={signInActMembershipLabel} />
            </div>
            <form {...form.props} className={css({ marginTop: '32px' })}>
                {fields.map(([name, label, type, rest]) => (
                    <Input
                        required
                        $style={{ marginTop: '24px' }}
                        errors={form.errors[name]}
                        key={name}
                        label={label}
                        maxLength="150"
                        name={name}
                        placeholder={label}
                        type={type}
                        {...rest}
                        onChange={handleChange}
                    />
                ))}
                <Checkbox
                    $style={{ marginTop: '24px', textAlign: 'left' }}
                    errors={form.errors.confirmMembership}
                    label={signInActMembershipConsent}
                    name="confirmMembership"
                    onChange={form.validateInput}
                />
                <Checkbox
                    required
                    $style={{ marginTop: '24px', textAlign: 'left' }}
                    errors={form.errors.confirmTerms}
                    label={signInActTosConsent}
                    name="confirmTerms"
                    onChange={form.validateInput}
                />

                {errors?.length > 0 && <InputErrors errors={errors} />}

                <ThemeButton
                    $style={{
                        width: '100%',
                        marginTop: '24px',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    disabled={isLoading}
                    theme="inverted"
                    type="submit"
                >
                    {isLoading ? (
                        <div
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                            })}
                        >
                            <LoadingIcon />
                        </div>
                    ) : (
                        <div
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            })}
                        >
                            <Paragraph fontKeys="Miscellaneous/24_100_-2">{fm('Complete profile')}</Paragraph>
                            <StateIcon size="16px" state="arrow-right" />
                        </div>
                    )}
                </ThemeButton>
            </form>
        </>
    );
};

export default SignInConsent;
